<template>
  <a :href="telegramLink" target="_blank" style="display: inline-block;" class="d-flex justify-content-center">
    <span
      style="background: #27A7E7; display: inline-block; width: 24px; height: 24px; position: relative; border-radius: 25px;"
    >
      <v-img src="@/assets/images/telegram.png" class="telegram-img" />
    </span>

    <span class="telegram-text">
      {{ $t("app.follow_us_on_telegram") }}
    </span>
  </a>
</template>

<script>
const telegramLink = {
  es: "https://t.me/costaction_es",
  tr: "https://t.me/costaction_tr",
  en: "https://t.me/costaction_eng",
  ru: "https://t.me/costaction_ru",
  de: "https://t.me/costaction_de",
  pt: "https://t.me/costaction_pt",
  fr: "https://t.me/costaction_fr",
  ar: "https://t.me/costaction_ar"
};

export default {
  computed: {
    telegramLink() {
      const lang = this.$store.state.auth.userData.lang;
      const link = telegramLink.hasOwnProperty(lang) ? telegramLink[lang] : telegramLink.en;

      return link;
    }
  }
};
</script>

<style>
.telegram-img {
  position: absolute;
  top: 7px;
  left: 4px;
  width: 14px;
}
.telegram-text {
  color: #27a7e8;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #27a7e7;
  display: flex;
  align-items: center;
  padding-inline-start: 18px;
  padding-inline-end: 8px;
  margin-inline-start: -15px;
  border-radius: 7px;
  background: white;
}
</style>
