<template>
  <div>
    <ca-page-header :title="$t('app.news_and_updates')" icon="mdi-bell" color="success"> </ca-page-header>

    <v-row justify="center" class="mb-4">
      <telegram-link />
    </v-row>

    <v-row justify="center">
      <v-col lg="8">
        <v-card v-for="item in news" :id="item.id" :key="item.id" class="mb-10">
          <v-card-title>{{ item.title }}</v-card-title>
          <v-card-subtitle>{{ item.date }}</v-card-subtitle>
          <v-card-text v-html="item.text" />
        </v-card>

        <v-pagination v-show="totalPages > 1" v-model="page" :length="totalPages" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaPageHeader from "../../components/PageHeader";
import TelegramLink from "../../components/TelegramLink";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.news")
    };
  },
  components: { TelegramLink, CaPageHeader },
  data() {
    return {
      news: [],
      page: 1,
      totalPages: 0
    };
  },
  watch: {
    page(page) {
      this.loadNews(page);
    }
  },
  mounted() {
    this.loadNews(1);
    this.setNewsRead();
  },
  methods: {
    loadNews(page) {
      this.axios.get("/news?page=" + page).then(response => {
        this.news = response.data.data;
        this.totalPages = response.data.meta.last_page;
      });
    },
    setNewsRead() {
      this.axios.post("/news/set-read");
    }
  }
};
</script>
